@import '../../../../../styles/variables.less';

.mainContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  overflow-x: auto;
  padding: 20px;
}

.floatingCard {
  background-color: var(--content-background);
  border-radius: 10px;
  box-shadow: 0 1px 4px fade(@black-color, 25%);
  max-width: 455px;
  padding: 60px 0 20px;
  width: 100%;

  @media screen and (max-width: @mobile-breakpoint) {
    padding: 30px 0 10px;
  }
}

.content {
  padding: 0 50px;
  @media screen and (max-width: @mobile-breakpoint) {
    padding: 0 25px;
  }
}

.footer {
  padding: 5px 50px 0;
  @media screen and (max-width: @mobile-breakpoint) {
    padding: 5px 25px 0;
  }
}
