@import '@variables/variables.less';

.card {
  width: 100%;
  margin-bottom: 0;

  :global(.ant-card-body) {
    height: 100%;
  }

  &:hover {
    cursor: grab;
  }
}

.topContainer {
  display: flex;

  flex-direction: row;
  justify-content: normal;
  margin-bottom: 10px;

  & > :first-child {
    width: 85%;
  }
}

.title {
  text-overflow: ellipsis;

  white-space: nowrap;
  overflow: hidden;
  font-size: @font-size-subtitle;
  font-weight: @font-weight-label;
  padding-bottom: 4px;
  max-width: 100%;
  width: auto;
}

.subTitle {
  font-size: @font-size-label-value;
  font-weight: @font-weight-value;
  padding-bottom: 4px;
}

.dashboardCardButtons {
  gap: @item-gap-2;

  display: flex;
  width: 15%;
  flex-direction: row;
  justify-content: flex-end;
}
