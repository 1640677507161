@import '@variables/variables';

.container {
  position: relative;
  min-height: 100vh;
}
.content {
  position: fixed;
  width: 100%;
  background-color: @white-color;
  padding-top: 20px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 18px;
}
