@import '../../../../styles/variables.less';

@icon-size: 22px;

.mainContainer {
  padding-left: 60px;
  padding-top: 15px;
}

:global(.gx-timeline-item) {
  &:first-child::before {
    border-left-style: solid;
  }
  &:last-child::before {
    border-left-style: solid;
  }
  &::before {
    border: 1px solid @primary-color;
  }
}

.colorBar {
  height: 10px;
}

.icon {
  font-size: @icon-size;
}

.divisionIcon {
  &:extend(.icon);
  color: @dark-blue;
  width: 24px;
}

.divisionName {
  color: @dark-blue;
}

.ellipsis {
  flex: 1 1 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  padding: 10px;
}

.options {
  flex: 1 1 0;
  text-align: end;
}

.title {
  &:extend(.ellipsis);
  font-size: 30px;
}

.footer {
  background-color: rgba(92, 108, 117, 0.05);
  color: @grey-blue;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.extraComponents {
  align-items: center;
  display: flex;
}

.avatar {
  background-color: @grey-blue;
}

.badge {
  align-items: center;
  display: flex;
  font-size: 24px;
  justify-content: center;
  top: -2px;

  &:after {
    display: none;
    left: 1px;
    top: 1px;
  }
}

.body {
  background-color: @white-color;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(@black-color, 0.2);
  overflow: hidden;
}

@media screen and (max-width: @mobile-breakpoint) {
  .mainContainer {
    padding-left: 26px;
    padding-top: 5px;

    &::before {
      left: 10px;
      top: 20px;
    }
  }

  .colorBar {
    height: 5px;
  }

  .badge {
    font-size: 14px;
    height: 22px;
    top: -2px;
    width: 22px;

    &:after {
      display: none;
      left: 1px;
      top: 1px;
    }
  }

  .title {
    font-size: 18px;
  }

  .divisionIcon {
    font-size: 18px;
  }

  .divisionName {
    font-size: 14px;
  }
}
