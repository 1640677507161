@import '@variables/variables.less';

.cutText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.commonButton {
  border-color: @primary-color;
  margin-bottom: 0px;
}

.infoCardSubTitle {
  div {
    width: 100%;
    &:extend(.cutText);
  }
}

.cardContainer :global(.ant-card-extra) {
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: auto !important;
}

.cardContainer :global(.ant-card) {
  margin-bottom: 0px;
  padding: 20px;

  @media screen and (max-width: @mobile-breakpoint) {
    padding: 10px;
  }
}

.cardContainer {
  padding-bottom: 10px;
  @media screen and (max-width: @mobile-breakpoint) {
    :global(.ant-card-actions) {
      border-radius: 0 0 6px 6px;
      background-color: @background-card;
    }
  }
}

.card {
  border: none;

  @media screen and (max-width: @mobile-breakpoint) {
    .card :global(.ant-card-actions) {
      border-radius: 0 0 6px 6px;
    }
  }
}

.card :global(.ant-card-head-title) {
  padding-bottom: 10px;
  width: 70%;
}

.card :global(.ant-card-extra) {
  padding-bottom: 12px;
  height: 50px;
  width: 30%;
  flex-direction: row-reverse;
}

.editIconButton {
  &:extend(.commonButton);
}

.deleteIconButton {
  border-color: @error-red;
  margin-bottom: 0px;

  &:hover {
    border-color: @error-red;
  }
}

.cloneIconButton {
  &:extend(.commonButton);
}

.addIconButton {
  &:extend(.commonButton);

  border: none;
}

.title {
  color: #000;
  font-size: 14px;
  font-weight: 400;

  @media screen and (max-width: @mobile-breakpoint) {
    font-size: 12px;
  }
}

.subTitle {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  width: 95%;
  &:extend(.cutText);
  @media screen and (max-width: @mobile-breakpoint) {
    font-size: 16px;
    width: 100%;
  }
}

.infoCardListContainer {
  display: flex;
  width: 100%;
  background: @background-card;
  justify-content: space-between;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 5px;
}

.infoLeftContent {
  display: flex;
  flex-direction: row;
  width: 65%;
  flex: auto;
}

.extraContent {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.infoRightContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  gap: 10px;
}

.infoLeftTitleAndSubtitle {
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  padding-right: 6px;
  justify-content: center;
}

.primary {
  background-color: @background-card;
}
.light {
  background: #fff;
}

.secondary {
  background-color: @background-card-secondary;
}

.emptyChildren {
  :global(.ant-card-extra),
  :global(.ant-card-head-title) {
    padding-bottom: 0px;
  }
}
