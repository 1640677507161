@import '@variables/variables.less';

.container {
  display: flex;
  align-items: center;
  height: 36px;
}

.customProgress {
  position: relative;
  width: 100%;
  bottom: 7px;
  padding-top: 2px;
  padding-bottom: 2px;

  :global(.ant-progress-bg) {
    height: 4px !important;
  }

  :global(.ant-progress-inner) {
    background-color: @progress-inner-c;
  }

  :global(.ant-progress-success-bg) {
    background-color: @progress-outer-c;
  }
}
.manualRefreshButton {
  width: 43px;
  height: 43px;
}

.manualButtonC {
  flex: 1;
  text-align: left;
  padding-left: 2px;
  padding-right: 2px;
}

.progressBarC {
  height: 5px;
}
