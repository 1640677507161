@import '@variables/variables.less';

.profileContainer {
  align-items: center;
  display: flex;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: calc(325em / 14) !important;
  overflow-y: auto;
  background-color: var(--menu-body-background) !important;
}

.header {
  font-size: calc(25em / 14);
  justify-content: center;
  padding: calc(20em / 25) calc(30em / 25);
  height: calc(66em / 25);
  box-shadow: none;
  background-color: var(--menu-body-background) !important;

  & > a {
    display: flex;
    flex: 1 0 auto;
    height: calc(20em / 25);
    width: 100%;
  }
}

.content {
  background-color: var(--menu-body-background) !important;
  padding-right: calc(27em / 14);
  padding-left: calc(24em / 14);
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
}

.devInfo {
  text-align: end;
  opacity: 0.8;
}

.compactOption {
  font-size: calc(12em / 14);
  height: auto;
  padding: calc(2em / 12) calc(5em / 12);
}

// SIDEBARDMENU
.menuElement {
  color: @white-color !important;
}

.menuElement:hover {
  color: @nav-dark-text-color !important;
}
