.chartInfoValue {
  width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.labelValueWrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
