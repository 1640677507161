@import '../../styles.module.less';

.stepContainer {
  overflow-y: auto;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.infoText {
  color: @primary-color;
  font-size: 16px;
}

.whiteText {
  color: @white-color;
}

.errorText {
  &:extend(.textSmall);
  color: @error-red;
}

.textInput {
  border: 2px solid @white-color;
  border-radius: 6px;
  padding: 7.9px 11px;
  transition: all 0.3s;

  &:focus {
    border-color: @primary-color;
    outline: 0;
  }
}

.logo {
  width: 60px;
  margin: 100px auto 30px 90px;

  @media screen and (max-width: @mobile-breakpoint) {
    margin: 0 auto 30px 0;
  }
}

.buttonsPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    padding: 0;
  }
}
