@import '@variables/variables.less';

.notAllowedMessage {
  display: flex;
  flex-direction: row;

  gap: @item-gap;

  :nth-child(2) {
    align-content: center;
  }

  margin-top: 10px;
  margin-bottom: 10px;

  opacity: 0.5;
}
