.button {
  align-items: center;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: var(--text-color-dm);
  display: flex;
  font-size: calc(15em / 14);
  height: calc(46em / 15);
  justify-items: flex-start;
  margin: 0;
  overflow: hidden;
  padding: 0 calc(5em / 15);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:focus,
  &:active,
  &:hover {
    .selected;
  }

  & > :global(.material-icons) {
    font-size: calc(24em / 15);
    margin-right: calc(18em / 24);
  }
}

.selected {
  border: 1px solid var(--menu-selection-background);
  background-color: var(--menu-selection-background);
  color: var(--menu-selection-color);
}

:global(.dark-mode) {
  .button {
    background-color: transparent;

    &:focus,
    &:active,
    &:hover {
      background-color: transparent;
    }
  }
}
