@import '@variables/variables.less';

.wifiIcon {
  color: #7d7d7d;
}

.containerStatusCommon {
  border-radius: 60px;
  padding: 4px;
  width: 110px;
}

.userActive {
  background-color: @user-active-bg;
  &:extend(.containerStatusCommon);
  color: #59aa2b;
}

.userInactive {
  background-color: @user-inactive-bg;
  &:extend(.containerStatusCommon);
}

.statusCount {
  background-color: @user-inactive-bg;
  &:extend(.containerStatusCommon);
}
