@import '@variables/variables';

.logo {
  width: 32px;
  height: 36px;
}

.context {
  width: 433px;
  height: 141px;
}

.guarnicText {
  font-size: 26px;
  font-weight: 700;
}

.bodyContainer {
  padding-top: 12px;
  width: 300px;

  p {
    text-align: center;
  }
  p.bodyTitle {
    font-size: 18px;
    font-weight: @font-weight-strong;
  }
  p.subTitle {
    font-size: 14px;
  }
}

.buttonC {
  width: 433px;

  @media screen and (max-width: @mobile-breakpoint) {
    width: 354px;
  }
}
