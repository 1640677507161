.icon {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  height: 1.5em;
  justify-content: center;
  left: auto;
  position: relative;
  top: auto;
  width: 1.5em;

  &:after {
    display: none;
  }
}

.iconWrap {
  align-self: center;

  :global(.material-icons-outlined) {
    font-size: 16px;
  }
}
