@import '../../styles/variables.less';

@input-border: #d9d9d9;

.mainContainer {
  display: flex;
  flex-direction: column;
}

.required {
  color: @nav-dark-bg;
}

.errorText {
  color: @error-red;
}

.input {
  border-radius: 6px;
  border: 2px solid @input-border;
  padding: 8px 11px;
  transition: all 0.3s;

  &:focus {
    border-color: @primary-color;
    outline: 0;
  }
}

.error {
  &:extend(.input);
  border: 2px solid @error-red !important;
}

:global(.dark-theme) {
  .required {
    color: @white-color;
  }

  .errorText {
    color: @error-red;
  }
}
