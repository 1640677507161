html {
  font-size: var(--text-size);
  line-height: var(--line-heigth);
}

body {
  background-color: var(--site-background);
  color: var(--text-color);
  font-size: inherit;
  line-height: inherit;
}
