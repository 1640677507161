@import '@variables/variables.less';

.displayDaysByTag {
  margin: 0 2px 0 2px;
}

// Schedule Status
@import '@variables/variables.less';

.incomplete {
  background-color: @exec-incomplete;
  color: @white-color;
}

.inactive {
  background-color: @exec-inactive;
  color: @white-color;
}

.completed {
  background-color: @exec-complete;
  color: @white-color;
}

.active {
  background-color: @exec-active;
  color: @white-color;
}

.tagStatus {
  width: 85px;
  text-align: center;
  margin-bottom: 0px;
}

.nowWrap {
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
