@import '@variables/variables.less';

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container {
  background: @white-color;
}
.body {
  background-color: @white-color;
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}

.boxA {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: @mobile-breakpoint) {
    max-height: 120px;
  }
}

.boxB {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.boxC {
  display: flex;
  justify-content: space-between;
  height: 50px;

  padding-top: 10px;
  padding-bottom: 10px;

  div {
    &:extend(.ellipsis);
  }

  button {
    padding-right: 0px;
    @media screen and (max-width: @mobile-breakpoint) {
      padding-right: 10px;
    }
  }
}

.description {
  align-self: center;
  width: 100%;
  padding-top: 2px;
}

.titleContainer {
  flex-grow: 1;

  display: flex;
  align-items: center;
  padding-right: 40px;
  @media screen and (max-width: @mobile-breakpoint) {
    width: 60%;
  }
}
.buttonsContainer {
  @media screen and (max-width: @mobile-breakpoint) {
    padding-left: 0px;
  }
}

.leftIcon {
  padding-right: 8px;
}

.titleChildren {
  @media screen and (max-width: @mobile-breakpoint) {
    div {
      width: 100%;
      padding-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
  height: 0px;
}

.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in;
}

.with-delay {
  transition-delay: 0.5s;
}
