:root {
  --site-background: var(--white);
  --site-background-dm: var(--grey-11);

  --content-background: var(--white);
  --content-background-dm: var(--grey-12);

  --separator-color: var(--grey-5);
  --separator-color-dm: var(--grey-7);

  --menu-body-background: var(--blue-9);
  --menu-body-background-dm: var(--grey-11);
  --menu-header-background: var(--blue-10);
  --menu-header-background-dm: var(--blue-9);
  --menu-selection-background: var(--blue-7);
  --menu-selection-background-dm: var(--grey-7);
  --menu-selection-color: var(--blue-4);
  --menu-selection-color-dm: var(--grey-4);

  --menu-selection-menu-selector-background: var(--blue-7);
  --menu-selection-menu-selector-background-dm: var(--blue-7);

  --menu-sidebar-background: var(--blue-9);
  --menu-sidebar-background-dm: var(--grey-11);
}

.dark-mode {
  --site-background: var(--site-background-dm);

  --content-background: var(--content-background-dm);

  --separator-color: var(--separator-color-dm);

  --menu-body-background: var(--menu-body-background-dm);
  --menu-header-background: var(--menu-header-background-dm);
  --menu-selection-background: var(--menu-selection-background-dm);
  --menu-selection-color: var(--menu-selection-color-dm);

  --menu-selection-menu-selector-background: var(--menu-selection-menu-selector-background-dm);
  --menu-sidebar-background: var(--menu-sidebar-background-dm);
}
