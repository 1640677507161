@import '../../../../../../styles/variables.less';

@max-height: 200px;

.wrapper {
  background-color: @white-color;
  display: flex;
  height: @max-height;
}

.carousel {
  ul {
    margin: 0;
    li button {
      height: 10px !important;
      border-radius: 15px !important;
      border: solid 1px grey !important;
    }
  }
}

@media screen and (max-width: @mobile-breakpoint) {
  .title {
    font-size: 13px;
  }

  .wrapper {
    height: 100px;
  }
}
