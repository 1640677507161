@import '@variables/variables.less';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: min-content;
  padding: calc(22em / 14) calc(20em / 14);
  max-width: 1280px;
  margin: 0 auto;
}

.breadcrumb {
  padding-bottom: calc(20em / 14);

  & > span {
    color: var(--text-secondary-color);

    & > span {
      color: inherit;

      & > a {
        color: inherit;

        &:focus,
        &:active,
        &:hover {
          color: var(--text-link-color);
        }
      }
    }

    &:last-of-type {
      color: var(--text-color);
      opacity: 1;
    }
  }
}

.title {
  color: var(--text-color);
  margin: 0;
  font-size: calc(28em / 14);
  padding: 0 0 calc(20em / 28);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  padding: 40px;

  border-radius: 10px;
  @media screen and (max-width: @mobile-breakpoint) {
    padding: 16px;
  }
}

.withBackground {
  background-color: @white-color;
}

.filterMode {
  background-color: @white-color;
  padding: 24px;

  border-radius: 8px;
}

.filters {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  padding-left: 16px;
  width: 100%;
}

.floatingContentCustom {
  left: 1px;
}

.floatingTitle {
  font-size: @font-size-title;
}

.floatingContent {
  display: block;
  width: 100%;
}

.childrenByFloating {
  padding-top: 80px;
}

.floatingSubtitle {
  max-width: 215px;
  font-size: @font-size-label-value;
  font-weight: @font-weight-label;
  margin-top: 2px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  &:extend(.text);
  font-size: @font-size-subtitle;
}
