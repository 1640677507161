@import '../../../../styles/variables.less';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: min-content;
  padding: calc(22em / 14) calc(20em / 14);
}

.breadcrumb {
  padding-bottom: calc(20em / 14);

  & > span {
    color: var(--text-secondary-color);

    & > span {
      color: inherit;

      & > a {
        color: inherit;

        &:focus,
        &:active,
        &:hover {
          color: var(--text-link-color);
        }
      }
    }

    &:last-of-type {
      color: var(--text-color);
      opacity: 1;
    }
  }
}

.title {
  color: var(--text-color);
  margin: 0;
  font-size: calc(28em / 14);
  padding: 0 0 calc(20em / 28);
}

.content {
  background-color: var(--content-background);
  border-radius: calc(4em / 14);
  border: 1px solid var(--separator-color);
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(1080em / 14);
  padding: calc(20em / 14);
}

.invisible {
  border: none;
  background: none;
}

.header {
  display: flex;
  justify-content: space-between;
  max-width: calc(1080em / 14);
}

@media screen and (max-width: @mobile-breakpoint) {
  .invisible {
    padding: 0;
  }

  .wrapper {
    padding: 10px;
    padding-top: 24px;
  }

  .breadcrumb {
    display: none;
  }

  .title {
    font-size: 20px;
  }
}
