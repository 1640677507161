@import '../variables.less';

@mobile-scale: 2.5px;
@desktop-scale: 5px;

@max: 20;

// Top margins

.m-top-loop (@i) when (@i > 0) {
  .m-top-@{i} {
    margin-top: (@i * @desktop-scale) !important;

    @media screen and (max-width: @mobile-breakpoint) {
      margin-top: (@i * @mobile-scale) !important;
    }
  }

  .m-top-loop(@i - 1);
}
.m-top-loop(@max);

// Bottom margins

.m-bottom-loop (@i) when (@i > 0) {
  .m-bottom-@{i} {
    margin-bottom: (@i * @desktop-scale) !important;

    @media screen and (max-width: @mobile-breakpoint) {
      margin-bottom: (@i * @mobile-scale) !important;
    }
  }

  .m-bottom-loop(@i - 1);
}
.m-bottom-loop(@max);

// Right margins

.m-right-loop (@i) when (@i > 0) {
  .m-right-@{i} {
    margin-right: (@i * @desktop-scale) !important;

    @media screen and (max-width: @mobile-breakpoint) {
      margin-right: (@i * @mobile-scale) !important;
    }
  }

  .m-right-loop(@i - 1);
}
.m-right-loop(@max);

// Left margins

.m-left-loop (@i) when (@i > 0) {
  .m-left-@{i} {
    margin-left: (@i * @desktop-scale) !important;

    @media screen and (max-width: @mobile-breakpoint) {
      margin-left: (@i * @mobile-scale) !important;
    }
  }

  .m-left-loop(@i - 1);
}
.m-left-loop(@max);

.m-top-0 {
  margin-top: 0 !important;
}

.m-right-0 {
  margin-right: 0 !important;
}

.m-bottom-0 {
  margin-bottom: 0 !important;
}

.m-left-0 {
  margin-left: 0 !important;
}
