@import '../../../../../../styles/variables.less';

@link-blue: #0075ff;

.link {
  color: @link-blue;
  flex: 1 1 0;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: @mobile-breakpoint) {
  .title {
    font-size: 13px;
  }
}
