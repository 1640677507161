@import '@variables/variables.less';

.requiredLabel::before {
  content: '*';
  color: red;
  margin-right: 4px;
}

.label {
  font-size: @font-size-label-value;
  font-weight: @font-weight-label;
}
.value {
  font-size: @font-size-label-value;
  font-weight: @font-weight-value;
}
