.texts {
  color: var(--text-color);

  & :global(label),
  & :global(span) {
    color: var(--text-secondary-color);
  }
}

.title {
  color: inherit;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.submitButton {
  width: 100%;
}

.subpanel {
  display: flex;
  justify-content: space-between;
}
