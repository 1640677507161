@import '@variables/variables.less';

.progressItemContainer {
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 10px;

  :global {
    ul.ant-card-actions > li {
      margin: 0px !important;
    }
    ul.ant-card-actions > li > span {
      cursor: default;
    }
  }
}

.status {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 22px;
}

// Execution progress colors

.active {
  background-color: @exec-activeBKG;

  .progressBar {
    background-color: @exec-active;
    height: 100%;
    left: 0;
    position: absolute;
  }

  span {
    color: @white-color;
    transition-duration: 0.3s;
    z-index: 1;
    margin: 2px;
  }
}

.inactive {
  background-color: @exec-inactive;
  height: 22px;
  span {
    color: @exec-inactive;
    margin: 2px;
  }
}

.incomplete {
  background-color: @exec-incompleteBKG;

  .progressBar {
    background-color: @exec-incomplete;
    height: 100%;
    left: 0;
    position: absolute;
  }

  span {
    color: @white-color;
    transition-duration: 0.3s;
    z-index: 1;
    margin: 2px;
  }
}

.delayed {
  background-color: @exec-delayedBKG;

  .progressBar {
    background-color: @exec-delayed;
    height: 100%;
    left: 0;
    position: absolute;
  }

  span {
    color: @white-color;
    transition-duration: 0.3s;
    z-index: 1;
    margin: 2px;
  }
}

.complete {
  background-color: @exec-complete;
  height: 22px;
  span {
    color: @white-color;
    margin: 2px;
  }
}

.expired {
  background-color: @exec-expired;

  .progressBar {
    background-color: @exec-expired;
    height: 100%;
    left: 0;
    position: absolute;
  }
}
