@import '@variables/variables.less';

.card {
  width: 100%;
  margin-bottom: 0;

  :global(.ant-card-body) {
    height: 100%;
  }

  &:hover {
    cursor: grab;
  }
}

.extraInfo {
  display: block;
  padding-left: 10px;
  font-size: 17px;
  z-index: 10;
  cursor: pointer;
}

.chartButtons {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: @mobile-breakpoint) {
    flex-direction: column;
  }
}

.chartTitle {
  text-overflow: ellipsis;

  white-space: nowrap;
  overflow: hidden;
  font-size: @font-size-subtitle;
  font-weight: @font-weight-label;
  padding-bottom: 4px;
  max-width: 200px;
  width: auto;
}

.chartTotalEvents {
  font-size: @font-size-label-value;
  font-weight: @font-weight-value;
  padding-bottom: 4px;
}

.triggerTitle {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: @font-weight-label;
}
