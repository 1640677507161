@import '../../node_modules/antd/lib/style/themes/default.less';

@main-container-width: 1280px;

// ColorPicker Variables
@white-color: #ffffff;
@black-color: #000000;
//global style variables
@dark-theme-primary-color: #38424b;
@dark-theme-secondary-color: lighten(@dark-theme-primary-color, 10%);

@primary-color: #3f8dd8;
@secondary-color: #fa8c15;
@text-color: #545454;
@text-color-secondary: #595959;
@heading-color: #535353;
@header-text-color: #262626;
@layout-header-background: #fefefe;
@layout-header-background-dark: #003365;
@layout-footer-background: #fffffe;
@body-background: #f5f5f5;
@nav-bar-bg: #fffffc;
@divider: #d8d8d8;
@nav-bar-text-color: @text-color;

@nav-dark-bg: #003366;
@nav-dark-text-color: #038fdd;
@nav-dark-highlight-color: #9BFCF6;
@menu-dark-bg: @nav-dark-bg;
@menu-dark-color: @white-color;
@menu-dark-highlight-color: @nav-dark-highlight-color;
@menu-dark-arrow-color: @nav-dark-text-color;
@hor-nav-text-color: #fffffd;

@border-radius-base: 6px;
@border-radius-sm: 4px;
@border-radius-lg: 10px;

@font-size-title: 28px;
@font-size-subtitle: 16px;
@font-size-label-value: 12px;

@font-weight-strong: 500;
@font-weight-label: 400;
@font-weight-value: 300;
// wieldy's end

@mobile-breakpoint: 800px;
@error-red: #ff766c;
@grey-color: #9f9f9f;
@light-grey-color: #c8c8c8;
@dark-blue: #407093;
@grey-blue: #365e7d;

:global(.dark-theme) {
  @divider: @white-color;
}

// Guarnic Look & Feel
@guarnic-primary: #01a2e3;
@guarnic-primary-dark: #016f9b;
@guarnic-icon-gray: #323032;
@guarnic-text-gray: #666;
@guarnic-text-gray-dark: #333;
@guarnic-empty-state: #00000040;

// Guarnic Fonts
@guarnic-font-header: 28px;
@guarnic-font-header-mobile: 22px;
@guarnic-font-label: 14px;
@guarnic-font-description: 12px;
@guarnic-font-headline-1: 20px;
@guarnic-font-headline-2: 16px;
@guarnic-font-12: 12px;

// Weight
@guarnic-font-weight-semi: 600;
@guarnic-font-weight-regular: 400;

// Avatar Colors
@avatar-background: #003366;
@avatar-group-background: #3f8dd8;
// Shadow cards
@shadow-list: -3px -1px 10px 0px rgba(0, 0, 0, 0.6);

// components
@progress-inner-c: #c4d5e5;
@progress-outer-c: #2596be;
// breakpoints
@breakpoint-sm: 576px;
@breakpoint-md: 768px;
@breakpoint-lg: 992px;
@breakpoint-xl: 1200px;
@breakpoint-xxl: 1600px;

// Cards
@background-card: #f5f5f5;
@background-card-secondary: #ffffff;

// Progress Bar - Executions
@exec-inactive: #9f9f9f;
@exec-complete: #3ea42d;
@exec-incomplete: #c82727;
@exec-incompleteBKG: #eccccc;
@exec-delayed: #d8ad3f;
@exec-delayedBKG: #efe7d1;
@exec-active: #3f8dd8;
@exec-activeBKG: #d1e0ef;
@exec-expired: #9f9f9f;
@exec-unassigned: #B641D9;

//animations
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

// Width Drawers
@drawer-p-6: 60%;

// User Status Colors
@user-active-bg: #59aa2b33;
@user-inactive-bg: #eaeaea;

//Circle Buttons
@circle-button-white: #f5f5f5;
@circle-button-blue: #1890ff;

@circle-button-primary: #f5f5f5;
@circle-button-danger: #e13e3b;
@circle-button-dark: #000000;

@circle-button-bg: #1890ff;
@circle-button-bg-2: #f5f5f5;
@circle-button-bg-3: #ffffff;

//Gap

@item-gap: 0.3125rem;
@item-gap-2: 0.625rem;
