@import '../../../../../../styles/variables.less';

@max-lines: 2;

.ellipsis {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @max-lines;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: @mobile-breakpoint) {
  .title {
    font-size: 13px;
  }
}
