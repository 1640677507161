@import '../../../../styles/variables.less';

.text {
  color: @dark-blue;
}

@media screen and (max-width: @mobile-breakpoint) {
  .text {
    font-size: 14px;
  }
}
