@import '@variables/variables.less';

.withBackground {
  background-color: @white-color;
}

.main {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.children {
  padding: 24px;
  border-radius: 8px;
  margin: 26px;

  @media screen and (max-width: @mobile-breakpoint) {
    border-radius: 0px;
    margin: 0px;
  }
}
