@import '../../../../styles/variables.less';

.subtitle {
  color: @black-color;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.blueText {
  color: @primary-color;
}
