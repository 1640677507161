@border-selector-radius: 8px;

.wrapper {
  --z-index: 10;
}

.content {
  position: relative;
}

.layer {
  display: none;
  background-color: transparent;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index);
}

.layerOpen {
  display: block;
}

.selector {
  background-color: var(--menu-selection-menu-selector-background);
  cursor: pointer;
  display: flex;
  font-size: calc(18em / 14);
  justify-content: space-between;
  padding: 0 calc(10em / 18);
  position: relative;
  width: 100%;

  & > :global(.material-icons) {
    font-size: calc(24em / 18);
    margin: 0;
    padding: 0 calc(5em / 18);
  }

  border-radius: @border-selector-radius;
}

.selectorOpen {
  z-index: calc(var(--z-index) + 2);
}

.optionContent {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty {
  font-size: calc(14em / 18);
  font-style: italic;
  opacity: 0.7;
  padding: 0 em(3em / 14);
}

.options {
  background-color: var(--menu-selection-menu-selector-background);
  border-radius: 2px;
  border: 1px solid var(--menu-selection-menu-selector-background);
  display: none;
  left: 0;
  padding-top: calc(55em / 14);
  position: absolute;
  top: 0;
  width: 100%;
}

.optionsOpen {
  display: block;
  z-index: calc(var(--z-index) + 1);
  border-radius: @border-selector-radius;
}

.option {
  cursor: pointer;
  font-size: calc(16em / 14);
  margin: calc(10em / 16) 0;
  padding: 0 calc(10em / 16);
}
