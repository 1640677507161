@import '../../../../styles.module.less';

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: max-content;
}

.activeStep {
  background-color: @primary-color;
  color: @white-color;
  min-height: 32px;
  min-width: 32px;
}

.stepContainer {
  @media screen and (max-width: @mobile-breakpoint) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.step {
  background-color: @white-color;
  color: @nav-dark-bg;
  min-height: 32px;
  min-width: 32px;
}

.divider {
  border-bottom: @primary-color 1px solid;
  width: 50px;

  @media screen and (max-width: @mobile-breakpoint) {
    width: 45px;
  }
}

@media screen and (max-width: @mobile-breakpoint) {
  .text {
    font-size: 10px;
  }
}
