@import '@variables/variables.less';

.sizeSm {
  width: 24px;
  height: 24px;

  span {
    font-size: 16px;
  }
}

.sizeMd {
  width: 36px;
  height: 36px;
}

.circleMain {
  border-radius: 50%;
  text-align: center;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.withBackGround {
  background-color: @circle-button-bg;

  @media screen and (max-width: @mobile-breakpoint) {
    background: @background-card;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.primary {
  color: @circle-button-primary;
}
.primaryBackground {
  background-color: @circle-button-bg;
}

.secondary {
  color: @circle-button-blue;
}
.secondaryBackground {
  background-color: @circle-button-white;
}

.danger {
  color: @circle-button-danger;
}
.dangerBackground {
  background-color: @circle-button-bg-2;
  border-style: solid;
  border-width: 1px;
  border-color: @circle-button-danger;
}

.dark {
  color: @circle-button-dark;
}
.darkBackground {
  background-color: @circle-button-bg-3;
}

.soft {
  color: @circle-button-dark;
}
.softBackground {
  background-color: @circle-button-bg-2;
}
