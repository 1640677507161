@import '@variables/variables.less';

.userSelected {
  background: white;
  width: 34px;
  height: 34px;
  align-content: center;
  text-align: center;
  border-radius: 50%;
  background-color: rgba(52, 175, 255, 0.4);
}

.locationUserAvatar {
  background-color: @guarnic-primary;
}
