@import '@variables/variables.less';

.colorPickerContainer {
  padding-top: 1.2rem;

  display: flex;
  justify-content: center;

  @media screen and (max-width: @mobile-breakpoint) {
    justify-content: normal;
    padding-left: 12px !important;
  }
}
