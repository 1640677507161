@import '../../../../styles/variables.less';

.progress {
  margin-bottom: 0;
  flex: 1 1 0;

  :global(.ant-progress-inner) {
    background-color: rgba(@primary-color, 0.3);
  }
}

.completition {
  font-size: 12px;
}

.title {
  color: @primary-color;
}

@media screen and (max-width: @mobile-breakpoint) {
  .title {
    font-size: 14px;
  }
}
