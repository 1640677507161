.tableCard {
  overflow-x: auto;
}

.tableCard {
  :global(.ant-spin-nested-loading) {
    height: 100%;
  }
  :global(.ant-spin-container) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}
