@import '@variables/variables.less';

.item {
  background: rgba(@black-color, 0.05);
  border-radius: 100px;
  border: 1px solid rgba(@black-color, 0.19);
  max-width: min-content;
}

.ellipsis {
  flex: 1 1 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
