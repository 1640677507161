@import '../../../styles/variables.less';

.mainContainer {
  background-color: @nav-dark-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 5% 0;
  width: 100%;

  @media screen and (max-width: @mobile-breakpoint) {
    padding: 35px;
  }
}

.text {
  color: @white-color;
}

.textSmall {
  &:extend(.text);
  font-size: 10px;
  font-weight: lighter;
}

.title {
  &:extend(.text);
  align-self: center;
  font-size: 50px;
}

.titleSmall {
  &:extend(.text);
  font-size: 30px;
}

.subtitleMedium {
  &:extend(.text);
  font-size: 30px;
  font-weight: lighter;
}

.subtitleSmall {
  &:extend(.text);
  font-size: 22px;
  font-weight: lighter;
}
