.socialOption {
  align-items: center;
  display: flex;
  width: 100%;
  background-color: var(--content-background);

  &:hover {
    background-color: var(--content-background);
  }
}

.socialTitle {
  flex: 1 1;
}
