@import '@variables/variables.less';

.bottonSpacingMain {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  width: 100%;
}

.box {
  width: @main-container-width;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background: #fff;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.05);
  height: 10vh;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alignLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
