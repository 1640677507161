@import '../../styles.module.less';

.button {
  margin-left: 50%;
  margin-top: 30px;
}

.logo {
  align-self: center;
  width: 100px;
}
