@import '@variables/variables.less';

@wipe-in-duration: 2s;
@wipe-in-timing-function: cubic-bezier(0.25, 1, 0.3, 1);

@keyframes wipe-in-down {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style='in:wipe:down'] {
  animation: @wipe-in-duration @wipe-in-timing-function wipe-in-down both;
}

.eventTypeMain {
  width: 100%;
  background-color: @white-color;
  padding: 10px;
  border-radius: 10px;
}

.eventTypeBackground {
  background-color: @background-card !important;
}

.eventIconCustom {
  div {
    width: 20px;
    height: 20px;
    span {
      font-size: 12px;
    }
  }
}

.locationsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: @mobile-breakpoint) {
    flex-direction: column;
  }

  div {
    margin-right: 4px;

    @media screen and (max-width: @mobile-breakpoint) {
      margin-right: 0px;
    }
  }
}

.editIconButton {
  border-color: @primary-color;
}

.eventTypeTime {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .cooldown {
    padding-right: 12px;
  }

  .avoidConsecutiveTriggering {
    padding-right: 12px;
    padding-left: 12px;
  }

  .expectedExecutions {
    padding-right: 12px;
    padding-left: 12px;
  }

  .description {
    padding-right: 12px;
  }

  @media screen and (max-width: @mobile-breakpoint) {
    flex-direction: column;

    .cooldown {
      padding-bottom: 6px;
    }
    .avoidConsecutiveTriggering {
      padding-right: 12px;
      padding-left: 0px;
      padding-bottom: 6px;
    }
    .expectedExecutions {
      padding-right: 12px;
      padding-left: 0px;
      padding-bottom: 6px;
    }

    .description {
      padding-right: 12px;
      padding-left: 0px;
      padding-bottom: 6px;
    }
  }
}

.progressByEvent {
  @media screen and (max-width: @mobile-breakpoint) {
    width: 50%;
  }
  width: 25%;
}

.executions {
  width: 30px;
}

.eventTypeV1Title {
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  gap: 5px;
}

.eventTypeV1 {
  display: flex;
  flex-wrap: wrap;
  gap: @item-gap;
  padding-bottom: 10px;

  & > * {
    flex: 1 1 calc(25% - (@item-gap));
  }

  @media screen and (max-width: @mobile-breakpoint) {
    & > * {
      flex: 50%;
    }

    & > :nth-child(3) {
      flex: 1 1 100%;
    }
  }
}

.eventTypeV1 {
  &.visible {
    animation: @wipe-in-duration @wipe-in-timing-function wipe-in-down both;
  }
}

.eventTypeV1HideBorder {
  :global(.ant-card-head) {
    border-bottom: 0px;
  }

  :global(.ant-card-head-wrapper) {
    & > div {
      flex: 1 1;
    }
  }

  @media screen and (max-width: @mobile-breakpoint) {
    :global(.ant-card-head-wrapper) {
      & > div {
        flex: none;
      }
    }
  }
}

.verticalBar {
  width: 1px;
  height: 18px;
  background-color: #000;
  margin: 0 10px;
  align-self: center;
  opacity: 0.1;
}
.sigleProgress {
  width: 150px;
  align-self: center;
  align-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  @media screen and (max-width: @mobile-breakpoint) {
    width: 45px;
  }
}
