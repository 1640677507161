@import '@variables/variables';

.floatingContent {
  left: 1px;
}

.inputFilterMobile {
  @media screen and (max-width: @mobile-breakpoint) {
    width: 97%;
  }
  width: 90%;
}

.unRelativeInput {
  width: 90%;

  @media screen and (max-width: @mobile-breakpoint) {
    width: 97%;
  }
}

.relativeInput {
  width: auto;
  display: flex;
  justify-content: center;

  @media screen and (max-width: @mobile-breakpoint) {
    width: 68px;
  }
}

.refreshInput {
  @media screen and (max-width: @mobile-breakpoint) {
    padding-top: 15px;
  }
}
