:root {
  --title-color: var(--grey-11);
  --title-color-dm: var(--grey-1);

  --text-color: var(--grey-10);
  --text-color-dm: var(--grey-2);

  --text-secondary-color: var(--grey-7);
  --text-secondary-color-dm: var(--grey-4);

  --text-size: 14px;
  --line-heigth: calc(22em / 14);

  --text-link-color: var(--blue-6);
  --text-link-color-dm: var(--blue-5);
}

.dark-mode {
  --title-color: var(--title-color-dm);
  --text-color: var(--text-color-dm);
  --text-secondary-color: var(--text-secondary-color-dm);
  --text-link-color: var(--text-link-color-dm);
}
