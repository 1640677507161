@import '../../styles/variables.less';

.cutText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.triggerListBody {
  background-color: @body-background;
  margin-bottom: 0px !important;
}

.triggerName {
  &:extend(.cutText);

  width: 10rem;
  margin: 2px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;

  @media screen and (min-width: 576px) {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    color: @white-color;
  }
}
.triggerNameTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  div {
    margin-left: 0.4rem;
  }
}
.triggerNameContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.triggerNameIcon {
  padding: 4px;
}
.triggerElementName {
  display: flex;
  padding: 0px;
  flex-direction: column;
  justify-content: center;
}

.sorteableList {
  padding-left: 0px;
  list-style-type: none;
}

.sortableItem {
  display: flex;
  flex-direction: row;
  width: 100%;
}
