@import '@variables/variables.less';

.center {
  margin: 0 auto;
}

.shadow {
  box-shadow: 0px 4px 10px 0px #0000000d;
}

.main {
  width: 100%;
  background-color: @white-color;
}

.wrapper {
  &:extend(.center);
  width: 100%;

  padding-top: 5px;
  padding-bottom: 20px;
  position: relative;
}

.content {
  &:extend(.center);
  max-width: @main-container-width;
  padding-left: 20px;
  padding-right: 20px;
}

.fixed {
  position: sticky;
  top: 0;
  z-index: 1000;
  margin-bottom: 20px;
}

.errorContainer {
  :global(.ant-result-subtitle) {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fullScreen {
  &:extend(.center);
  padding-left: 20px;
  padding-right: 20px;
}

.bottonSpacingContainer {
  padding-bottom: 80px; // calculate the height of the button according to button container height (10vh)
}
