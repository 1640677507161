@import '@variables/variables.less';

.locationAlertWrapper {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;

  & > div:first-child {
    height: 80%;
  }
}
