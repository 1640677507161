@import '../../../../../styles/variables.less';

.title {
  color: @black-color;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.submitButton {
  width: 100%;
}

.subpanel {
  display: flex;
  justify-content: space-between;
}
