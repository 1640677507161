@import '@variables/variables.less';

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitleFloating {
  text-transform: capitalize;
}

.userColumn {
  &:extend(.text);
}

.usersTable {
  :global(.ant-table-thead .ant-table-cell) {
    background-color: @background-card;

    min-height: 500px;
  }

  :global(.ant-table-tbody > tr > td) {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  :global(.ant-pagination) {
    text-align: center;
  }

  :global(.ant-table-body) {
    min-height: 500px;
  }
}

.auxColumns {
  color: @avatar-group-background;
}

.userFocused {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: @avatar-group-background;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: white !important;
  }
}
