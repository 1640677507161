@import '../../../../styles/variables.less';

@em: 15em;

.button {
  align-items: center;
  background: @primary-color;
  border-radius: (5 / @em);
  border: (1 / @em) solid @primary-color;
  box-shadow: 0 0 (5 / @em) rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  color: @white-color;
  cursor: pointer;
  display: inline-flex;
  font-size: 15px;
  height: (35 / @em);
  max-width: (305 / @em);
  overflow: hidden;
  padding: 0 (7 / @em) 0 (16 / @em);
  width: 100%;

  &:hover {
    border-color: @nav-dark-bg;
  }

  &:focus {
    border-color: @secondary-color;
  }
}

.text {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  overflow: hidden;
  text-align: start;
}

.textOptions {
  flex: 0 1 auto;
  font-size: (12 / @em);
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textOptionsCount {
  flex: 0 1 auto;
  font-size: (12 / @em);
  padding-left: (7 / @em);
}

.actions {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  justify-content: flex-end;
}

.icon {
  flex: 0 1 auto;
  padding: 0 (8 / @em);

  & > :global(.icon-close) {
    align-items: center;
    border-radius: (2 / @em);
    border: (1 / @em) solid currentColor;
    display: flex;
    justify-content: center;
  }

  &:global(.icon-charvlet-down) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
