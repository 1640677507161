@import '@variables/variables.less';

.tableContainer {
  border-collapse: separate;
  border-spacing: 0;
  border: none;
}

.tableContainer {
  th {
    background-color: #f5f5f5;

    &:first-child {
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
    }
  }
}

.tableContainer {
  td {
    border: 0.5px solid rgba(130, 130, 130, 0.3);
    background: #fff;
  }
}

.tableContainer {
  th,
  td {
    padding: 10px;
  }
}

.tableContainer {
  tbody {
    tr {
      @media screen and (max-width: @mobile-breakpoint) {
        border: none;
      }
    }
  }
}

.alignRight {
  text-align: right;
}
.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.tablePrimary td {
  @media screen and (max-width: @mobile-breakpoint) {
    background-color: @background-card;
  }
}
