.button {
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: var(--text-color-dm);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  justify-items: flex-start;
  flex-direction: column;
  font-size: calc(15em / 14);
  min-height: calc(46em / 15);
  margin: 0;
  padding: 12px calc(5em / 15) 0;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    .selected;
  }

  :global(.material-icons) {
    font-size: calc(24em / 15);
    margin-right: calc(18em / 24);
  }
}

.name {
  display: flex;
  padding-bottom: 12px;
}

.selected {
  border: 1px solid var(--menu-selection-background);
  background-color: var(--menu-selection-background);
  color: var(--menu-selection-color);
}

:global(.dark-mode) {
  .button {
    background-color: transparent;

    &:focus,
    &:active,
    &:hover {
      background-color: transparent;
    }
  }
}
