@import '@variables/variables.less';

.mapContainer {
  height: 50vh;
  margin-bottom: 20px;
  z-index: 1;
}

.xl {
  height: 100vh;
}

.md {
  height: 50vh;
}

.sm {
  height: 30vh;
}

.userDetailPopup {
  width: 450px;

  :global(.leaflet-popup-content) {
    margin: 10px;
    max-width: 100%;
    width: auto !important;
  }

  @media screen and (max-width: @mobile-breakpoint) {
    width: 225px;
  }
}

// UerDetails Styles
.blockCommon {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.userDetailMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 12px;
}

.blockA {
  &:extend(.blockCommon);

  @media screen and (max-width: @mobile-breakpoint) {
    :first-child {
      white-space: nowrap;
    }
  }
}

.blockB {
  &:extend(.blockCommon);
  @media screen and (max-width: @mobile-breakpoint) {
    flex-direction: column;
  }
}
.blockC {
  &:extend(.blockCommon);
  @media screen and (max-width: @mobile-breakpoint) {
    flex-direction: column;
  }
}

.popupUserTime {
  text-align: end;
}

.userSelected {
  width: 75px !important;
  height: 75px !important;
  border-radius: 50%;
  background-color: rgba(52, 175, 255, 0.4);

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.mapContainer {
  height: 50vh;
  z-index: 1;
}

.currentLocation {
  border: none;
  background: none;

  width: 26px !important;
  height: 26px !important;

  background: aliceblue;
  border-radius: 100%;
  align-content: center;
  margin: 0 auto;
  text-align: center;

  box-shadow: 0 0 10px rgba(52, 175, 255, 1);
  color: @guarnic-primary;

  span {
    margin-top: 4px;
    font-size: 16px;
  }
}
