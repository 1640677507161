@import '@variables/variables.less';

.locationAlertForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.locationAlertFormIcon {
  span {
    font-size: 66px;
    color: @primary-color;

    margin-bottom: 20px;
  }
}

.descriptionTitle {
  > * {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
  }
}

.formTitle {
  white-space: pre-line;
}
