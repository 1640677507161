.sorteableList {
  padding-left: 0px;
  list-style-type: none;
}

.sortableItem {
  display: flex;
  flex-direction: row;
  width: 100%;
}
