@import '@variables/variables.less';

.gx-guarnic-header {
  font-size: @guarnic-font-header;
  font-weight: @guarnic-font-weight-semi;
  color: @guarnic-text-gray-dark;

  @media screen and (max-width: @mobile-breakpoint) {
    font-size: @guarnic-font-header-mobile;
  }
}

.gx-guarnic-label {
  font-size: @guarnic-font-label;
  font-weight: @guarnic-font-weight-semi;
  color: @guarnic-text-gray-dark;
}

.gx-guarnic-card-title {
  font-size: @guarnic-font-headline-2;
  font-weight: @guarnic-font-weight-semi;
  color: @guarnic-text-gray-dark;
}

.gx-guarnic-body {
  font-size: @guarnic-font-label;
  font-weight: @guarnic-font-weight-regular;
  color: @guarnic-text-gray-dark;
}

.gx-guarnic-body-light {
  font-size: @guarnic-font-label;
  font-weight: @guarnic-font-weight-regular;
  color: @guarnic-text-gray-dark;
  opacity: 50%;
}

.gx-guarnic-headline-1 {
  font-size: @guarnic-font-headline-1;
  font-weight: @guarnic-font-weight-semi;
  color: @guarnic-text-gray-dark;
}

.gx-guarnic-headline-2 {
  font-size: @guarnic-font-headline-2;
  font-weight: @guarnic-font-weight-semi;
  color: @guarnic-text-gray-dark;
}

.gx-guarnic-description {
  font-size: @guarnic-font-description;
  font-weight: @guarnic-font-weight-regular;
  color: @guarnic-text-gray;
}

.gx-guarnic-title-filter {
  font-size: @guarnic-font-12;
  font-weight: @guarnic-font-weight-regular;
}

// Paddings
.gx-guarnic-pb-1 {
  padding-bottom: 5px;
}
.gx-guarnic-pt-1 {
  padding-top: 5px;
}

.gx-guarnic-pb-2 {
  padding-bottom: 10px;
}
.gx-guarnic-pt-2 {
  padding-top: 10px;
}

.gx-guarnic-pb-3 {
  padding-bottom: 20px;
}
.gx-guarnic-pt-3 {
  padding-top: 20px;
}

.gx-guarnic-mb-1 {
  margin-bottom: 5px;
}
.gx-guarnic-mt-1 {
  margin-top: 5px;
}

.gx-guarnic-mb-2 {
  margin-bottom: 10px;
}
.gx-guarnic-mt-2 {
  margin-top: 10px;
}

.gx-guarnic-mb-3 {
  margin-bottom: 20px;
}
.gx-guarnic-mt-3 {
  margin-top: 20px;
}

// Gaps
.gx-guarnic-gap-1 {
  gap: @item-gap;
}

.gx-guarnic-gap-2 {
  gap: @item-gap-2;
}

.gx-guarnic-break-word {
  white-space: normal;
  overflow-wrap: break-word;
}

//Colors
.gx-text-blue-primary {
  color: @primary-color;
}
