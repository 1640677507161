@import '../../../../../../styles/variables.less';

@max-height: 200px;

.wrapper {
  background-color: @white-color;
  display: flex !important;
  height: @max-height;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: @mobile-breakpoint) {
    height: 100px;
  }
}

.carousel {
  ul {
    margin: 0;
    li button {
      height: 10px !important;
      border-radius: 15px !important;
      border: solid 1px grey !important;
    }
  }
}

.play {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(@black-color, 0.42);
}

.playIcon {
  color: darkgrey;
  font-size: 48px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: @mobile-breakpoint) {
  .title {
    font-size: 13px;
  }
}
