.dayContainer {
  margin-bottom: 55px;

  .day {
    font-size: 20px;
    color: #000;
  }
}

.progressItemsList {
  display: flex;
  flex-direction: column;
}
