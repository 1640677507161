@import '@variables/variables.less';

.locationAvatar {
  color: @primary-color;
  cursor: pointer;
}

.primary {
  background-color: #f5f5f5;
}

.light {
  background-color: #ffffff;
}

.column {
  display: flex;
  flex-direction: column;
}

.rowCenter {
  align-items: center;
  display: flex;
}

.info {
  &:extend(.column);
  flex: 1 1 auto;
}

.buttonIcon {
  font-size: 24px;
}

.boldText {
  color: @black-color;
  font-weight: 500;
}

.type {
  font-weight: lighter;
}

.editContainer {
  align-self: flex-end;
  display: flex;
}

.bordered {
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(@black-color, 0.25);
  padding: 16px;
}

.selected {
  background-color: @primary-color;
}

// Location Element

.locationText {
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  flex-direction: column;
  justify-content: center;
}

.locationContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.avatarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.locationActionButtons {
  cursor: pointer;
}

.gpsDescription {
  margin-top: 5px;
}

.withoutBackGround {
  background: none !important;
  border: none !important;
}

.locationCard {
  margin: 0px !important;
}

.locationCombinerMain {
  :global(.ant-radio-button-wrapper) {
    padding: 0 8px;
  }
}
