:root {
  --white: #ffffff;
  --black: #000000;

  --grey-1: var(--white);
  --grey-2: #fafafa;
  --grey-3: #f5f5f5;
  --grey-4: #f0f0f0;
  --grey-5: #d9d9d9;
  --grey-6: #bfbfbf;
  --grey-7: #8c8c8c;
  --grey-8: #595959;
  --grey-9: #434343;
  --grey-10: #262626;
  --grey-11: #1f1f1f;
  --grey-12: #141414;
  --grey-13: var(--black);

  --cyan-1: #e6fffb;
  --cyan-2: #b5f5ec;
  --cyan-3: #87e8de;
  --cyan-4: #5cdbd3;
  --cyan-5: #36cfc9;
  --cyan-6: #13c2c2;
  --cyan-7: #08979c;
  --cyan-8: #006d75;
  --cyan-9: #00474f;
  --cyan-10: #002329;

  --blue-1: #e6f7ff;
  --blue-2: #bae7ff;
  --blue-3: #91d5ff;
  --blue-4: #77fff7;
  --blue-5: #40a9ff;
  --blue-6: #1890ff;
  --blue-7: #064380;
  --blue-8: #0050b3;
  --blue-9: #003366;
  --blue-10: #002447;

  --geekblue-1: #f0f5ff;
  --geekblue-2: #d6e4ff;
  --geekblue-3: #adc6ff;
  --geekblue-4: #85a5ff;
  --geekblue-5: #597ef7;
  --geekblue-6: #2f54eb;
  --geekblue-7: #1d39c4;
  --geekblue-8: #10239e;
  --geekblue-9: #061178;
  --geekblue-10: #030852;

  --yellow-1: #feffe6;
  --yellow-2: #ffffb8;
  --yellow-3: #fffb8f;
  --yellow-4: #fff566;
  --yellow-5: #ffec3d;
  --yellow-6: #fadb14;
  --yellow-7: #d4b106;
  --yellow-8: #ad8b00;
  --yellow-9: #876800;
  --yellow-10: #614700;

  --red-1: #fff1f0;
  --red-2: #ffccc7;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --red-7: #cf1322;
  --red-8: #a8071a;
  --red-9: #820014;
  --red-10: #5c0011;

  --green-1: #f6ffed;
  --green-2: #d0f7be;
  --green-3: #b7ed8f;
  --green-4: #95de64;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --green-8: #237804;
  --green-9: #135200;
  --green-10: #092b00;
}
