@import '@variables/variables.less';

.cutText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.oneLine {
  display: flex;
  flex-direction: row;
}
