@import '../../styles/variables.less';

.listContainer :global {
  .ant-list-header {
    padding: 0px !important;
  }

  @media screen and (max-width: @mobile-breakpoint) {
    right: 12px;
  }
}

.memberEmptyState :global {
  .ant-spin-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 3em;
  }
  .ant-list-empty-text {
    padding: 4px;
  }
}

.avatarColor {
  background-color: @avatar-background;
  color: @white-color;
  font-size: 12px !important;
}

.avatarContainer {
  padding-right: 24px;
  padding-left: 0;
  @media screen and (max-width: @mobile-breakpoint) {
    padding-left: 12px;
  }
}

.headerList {
  @media screen and (max-width: @mobile-breakpoint) {
    padding-left: 12px;
  }
}

.headerTitle {
  font-size: 12px;
}

.loadingMore {
  display: flex;
  flex-direction: column;
  max-height: 35px;
  height: auto;
  padding: 6px 6px;
}

.userContent {
  font-size: 12px;
  padding-top: 5px;
}

.userContainer {
  display: flex;
  padding-left: 8px;
}

.cutText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.memberAvatar {
  background-color: @avatar-background;
  color: @white-color;
}

.memberAvatarGroup {
  background-color: @avatar-group-background;
  color: @white-color;
}

.customList {
  padding: 6px;
}

.customListItem {
  background-color: @background-card;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  :global(.ant-list-item-meta-title) {
    margin-top: 4px;
  }
}

.customListGroup {
  background-color: @background-card;
  padding-left: 6px;
  padding-right: 6px;

  :global(.ant-list-item-meta-title) {
    margin-top: 4px;
  }
}

.centerAvatar {
  div {
    :global(.ant-list-item-meta-avatar) {
      margin-top: 6px;
    }
  }
}

.customLisGroupC {
  .customListGroup:first-child {
    border-radius: 8px 8px 0 0;
  }
  .customListGroup:last-child {
    border-radius: 0 0 8px 8px;
  }

  li {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.customListGroupDescription {
  color: @guarnic-text-gray;
  font-size: 12px;
  font-weight: 200;
}

.avatarGroup {
  background-color: @guarnic-primary;
}

.container {
  display: flex;
  flex-wrap: wrap;

  .avatarContainer {
    width: 30px;
  }

  .userNameContainer {
    flex: 1;
    align-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.membersContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;

  flex-wrap: wrap;
  flex-basis: calc(100% - 20px);
  flex-grow: 1;

  @media screen and (max-width: @mobile-breakpoint) {
    display: flex;
    flex-direction: column !important;
  }
}

// Memeber User
.displayName {
  display: flex;
  flex-direction: row;
  width: 220px;
}
.displayName div:last-child {
  &:extend(.cutText);
}

.seenStatus {
  color: #1890ff;
}
