@import '@variables/variables.less';

.inputForm {
  width: 95%;
  padding-left: 12px;
  padding-right: 12px;

  @media screen and (max-width: @mobile-breakpoint) {
    width: 83%;
  }

  input {
    width: 100%;
  }
}

.dragIcon {
  width: 2%;
  padding-top: 10px;

  @media screen and (max-width: @mobile-breakpoint) {
    width: 10%;
  }
}

.formOptions {
  width: 98%;
  @media screen and (max-width: @mobile-breakpoint) {
    width: 90%;
  }
}

.selectContainer {
  :global(.ant-form-item-label) {
    padding: 0px;
  }
}
