@import '@variables/variables.less';

@section-padding: 14px;

@-webkit-keyframes fade-in-up {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-up {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.fade-in-left {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.roll-out-left {
  -webkit-animation: roll-out-left 0.6s ease-in both;
  animation: roll-out-left 0.6s ease-in both;
}

.slideOpenButton {
  &:extend(.heartbeat);
  cursor: pointer;
}

.slidePanelContainer {
  position: fixed;
  z-index: 1000;
  &:extend(.fade-in-left);
  width: 20%;
  min-width: 200px;
  height: 100%;

  @media screen and (max-width: @mobile-breakpoint) {
    -webkit-animation: fade-in-up 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-up 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    position: fixed;
    bottom: 0;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    height: 70%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.slidePanelContainerHidden {
  width: 4%;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  z-index: 1001;
  top: 8%;

  @media screen and (max-width: @mobile-breakpoint) {
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    height: 7%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    top: auto;

    :global(span.material-symbols-outlined) {
      transform: rotate(270deg);
      animation: none;
      transform-origin: left;
    }
  }
}

.slideHeader {
  height: 60px;
  background-color: #f5f5f5;
  padding: @section-padding;
}

.slideBody {
  height: 85%;
  overflow: auto;
  background-color: @white-color;
  padding: @section-padding;
}

.separator {
  border: 0;
  border-top: 0.5px solid #000000;
}

.noStyleList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 30px;

  span {
    font-weight: 400;
  }
}

.boardTitle {
  font-size: 14px;
  color: #000000;
  line-height: normal;
  font-weight: 600;
}

.selectedBoard {
  color: @primary-color !important;
}
.unSelectedBoard {
  color: #000000;
}

.divisionColor {
  color: @grey-color;
}

.blockContainer {
  margin-top: 10px;
}

.blockElementText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
}
